import { defineAsyncComponent, Plugin } from 'vue';
import PortalVue from 'portal-vue';
import IdeoPlugin from '@/components/bootstrap';
import DynamicFormsPlugin from '@/components/forms';

import ActionBar from '@/components/action/ActionBar.vue';
import ActionButton from '@/components/action/ActionButton.vue';
import ActionHeader from '@/components/action/ActionHeader.vue';
import ActionIcon from '@/components/action/ActionIcon.vue';
import ListView from '@/components/list/ListView.vue';
import ListViewHeader from '@/components/list/properties/ListViewHeader.vue';

import ListViewImage from '@/components/list/properties/ListViewImage.vue';
import ListViewLabel from '@/components/list/properties/ListViewLabel.vue';
import ListViewItem from '@/components/list/properties/ListViewItem.vue';
import ListViewButtons from '@/components/list/properties/ListViewButtons.vue';
import ListViewButton from '@/components/list/properties/ListViewButton.vue';
import Confirmation from '@/components/common/Confirmation.vue';

import RenterFloatingButton from '@/modules/renter/components/RenterFloatingButton.vue';

const ComponentsPlugin: Plugin =
{
    async install(app, options)
    {
        // Ideo Bootstrap Components
        app.use(IdeoPlugin);

        // Custom components
        app.component('ActionBar', ActionBar);
        app.component('ActionButton', ActionButton);
        app.component('FloatingButton', RenterFloatingButton);
        app.component('ActionHeader', ActionHeader);
        app.component('ActionIcon', ActionIcon);
        app.component('ListView', ListView);
        app.component('ListViewHeader', ListViewHeader);
        app.component('ListViewImage', ListViewImage);
        app.component('ListViewLabel', ListViewLabel);
        app.component('ListViewItem', ListViewItem);
        app.component('ListViewButtons', ListViewButtons);
        app.component('ListViewButton', ListViewButton);
        app.component('Confirmation', Confirmation);

        // Custom async components
        app.component('Editor', defineAsyncComponent(() => import('@/components/common/Editor.vue')));
        app.component('Filepicker', defineAsyncComponent(() => import('@/components/common/FilePicker.vue')));
        app.component('Fileupload', defineAsyncComponent(() => import('@/components/common/FileUpload.vue')));
        app.component('Pagination', defineAsyncComponent(() => import('@/components/common/Pagination.vue')));
        app.component('PaginationInfo', defineAsyncComponent(() => import('@/components/common/PaginationInfo.vue')));
        app.component('Responsive', defineAsyncComponent(() => import('@/components/common/Responsive.vue')));
        app.component('Sort', defineAsyncComponent(() => import('@/components/common/Sort.vue')));
        app.component('DataCard', defineAsyncComponent(() => import('@/components/data/DataCard.vue')));
        app.component('DataSize', defineAsyncComponent(() => import('@/components/data/DataSize.vue')));
        app.component('DataSorting', defineAsyncComponent(() => import('@/components/data/DataSorting.vue')));
        app.component('DownloadApp', defineAsyncComponent(() => import('@/components/common/DownloadApp.vue')));
        app.component('Placeholder', defineAsyncComponent(() => import('@/components/placeholders/Placeholder.vue')));
        app.component('PlaceholderForm', defineAsyncComponent(() => import('@/components/placeholders/Form.vue')));
        app.component('PlaceholderGrid', defineAsyncComponent(() => import('@/components/placeholders/Grid.vue')));
        app.component('Filemanager', defineAsyncComponent(() => import('@/components/filemanager/Component.vue')));
        app.component('GridFilter', defineAsyncComponent(() => import('@/components/filters/GridFilter.vue')));
        app.component('GridFilterWrapper', defineAsyncComponent(() => import('@/components/filters/GridFilterWrapper.vue')));
        app.component('Translate', defineAsyncComponent(() => import('@/modules/renter/components/RenterTranslate.vue')));
        app.component('TranslateMultiple', defineAsyncComponent(() => import('@/modules/renter/components/RenterTranslateMultiple.vue')));

        // Formularze dynamiczne
        app.use(DynamicFormsPlugin);

        // External components
        app.use(PortalVue);
    }
};

export default ComponentsPlugin;
