import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import { FilterModel } from "../services/NotesService";

export default {
    namespaced: true,
    state: {
        filter: Form.createPersistedState<FilterModel>({
            coordinatorPublicId: null,
            regionPublicId: null,
            propertyPublicId: null,
            searchDate: '',
            status: '',
            citiesIds: [],
            districtsIds: []
        }, undefined, 'rentersNotesFilters'),
        pager: new Pager(1, 100, 'Content', 'ASC')
    }
};
