import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import { FilterModel } from "../types";

export default {
    namespaced: true,
    state: {
        filter: Form.createPersistedState<FilterModel>({
            show: 'Active',
            citiesIds: [],
            districtsIds: [],
            propertiesIds: [],
            regionPublicId: null,
            showOwnerBooking: false,
            ownerPublicId: null,
            avantioId: null,
            department: null
        }, undefined, 'renterCalendarFilter'),
        pager: new Pager(1, 100, 'PropertyName', 'ASC'),
        refetchProperties: false,
        isHourViewActive: true,
        savedScrollPosition: 0
    }
};
