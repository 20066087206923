import { Form } from "@/helpers/Form";

export default {
    namespaced: true,
    state: {
        filters: Form.createPersistedState<any>({
            range: 'today',
            startDateUtc: null,
            endDateUtc: null,
            propertyPublicId: null,
            ownerBooking: false
        }, undefined, 'renterTasksManagementFilters'),
        sort: 'date',
        taskChecklist: {},
        taskPropertyPublicId: null,
        taskInspection: {
            form: null,
            itemsLoaded: false
        },
        photosToUpload: {}
    }
};
