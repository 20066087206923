import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import { FilterModel } from "../services/TasksService";

export default {
    namespaced: true,
    state: {
        filter: Form.createPersistedState<FilterModel>({
            propertyPublicId: null,
            assignedUserPublicId: null,
            createdByUserPublicId: null,
            completedByUserPublicId: null,
            departments: [],
            priority: [],
            status: [],
            dueDateFromUtc: null,
            dueDateToUtc: null,
            dateCreatedFromUtc: null,
            dateCreatedToUtc: null,
            dateCompletedFromUtc: null,
            dateCompletedToUtc: null,
            citiesIds: [],
            districtsIds: [],
            tagsIds: null,
        }, undefined, 'renterTasksFilter'),
        pager: new Pager(1, 100, 'DueDate', 'DESC')
    }
};
