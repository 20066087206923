import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import { FilterModel, TableFilterModel } from '../services/CostReportsService';

export default {
    namespaced: true,
    state: {
        costReportsListFilter: Form.createPersistedState<FilterModel>({
            avantioId: null,
            reservationLocalizator: null,
            reservationSource: null,
            priceFrom: null,
            priceTo: null,
            paidFrom: null,
            paidTo: null,
            propertyPublicId: null,
            cityPublicId: null,
            regionPublicId: null,
            ownerPublicId: null,
            createdByUserPublicId: null,
            dateCreatedFromUtc: null,
            dateCreatedToUtc: null,
            dateStatusChangeFromUtc: null,
            dateStatusChangeToUtc: null,
            belongsToOptions: [],
            statusOptions: [],
        }, undefined, 'rentersCostReportsFilters'),
        costReportsListPager: new Pager(1, 100, 'dateCreatedUtc', 'DESC'),
        costMonthlyReportFilter: Form.createPersistedState<FilterModel>({
            avantioId: null,
            reservationLocalizator: null,
            reservationSource: null,
            priceFrom: null,
            priceTo: null,
            paidFrom: null,
            paidTo: null,
            propertyPublicId: null,
            cityPublicId: null,
            regionPublicId: null,
            ownerPublicId: null,
            createdByUserPublicId: null,
            dateCreatedFromUtc: null,
            dateCreatedToUtc: null,
            dateStatusChangeFromUtc: null,
            dateStatusChangeToUtc: null,
            belongsToOptions: ['Guest'],
            statusOptions: ['CallForPaymentSent', 'AnotherCallForPayment', 'NotPaid'],
        }, undefined, 'rentersCostReportsMonthlyFilters'),
        costMonthlyReportPager: new Pager(1, 10, 'dateCreatedUtc', 'DESC'),
        costMonthlyReportTableFilter: Form.createPersistedState<TableFilterModel>({
            regionPublicId: null,
            dateFromUtc: null,
            dateDueUtc: null
        }, undefined, 'rentersCostReportsMonthlyTableFilters')
    }
};
