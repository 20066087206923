import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import { FilterModel } from "../services/CustomPropertiesService";

export default {
    namespaced: true,
    state: {
        filter: Form.createPersistedState<FilterModel>({
            citiesIds: [],
            showNonActive: false
        }, undefined, 'renterCustomPropertiesFilter'),
        pager: new Pager(1, 100, 'propertyName', 'ASC')
    }
};
