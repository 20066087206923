import { Form } from "@/helpers/Form";
import type { FilterModel } from "../types/index";

export default {
    namespaced: true,
    state: {
        filter: Form.createPersistedState<FilterModel>({
            searchDateUtc: null,
            citiesIds: [],
            districtPublicId: null,
            regionPublicId: null,
            showAllCleaningTask: false
        }, undefined, 'renterWarehouseFilter')
    }
};
