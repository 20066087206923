import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import { FilterModel } from "../services/CitiesService";

export default {
    namespaced: true,
    state: {
        filter: Form.createPersistedState<FilterModel>({
            cityPublicId: null,
            regionPublicId: null,
            switchDate: null
        }, undefined, 'renterCitiesFilter'),
        pager: new Pager(1, 100, 'Region', 'ASC')
    }
};
