import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import type { FilterModel } from "../types";

export default {
    namespaced: true,
    state: {
        filter: Form.createPersistedState<FilterModel>({
            districtsIds: [],
            citiesIds: []
        }, undefined, 'renterChecklistsFilter'),
        pager: new Pager(1, 100, 'Name', 'ASC')
    }
};
