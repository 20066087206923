<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { AuthModel } from "@/modules/core/auth/services/AuthService";

@Options({})
export default class PushNotifications extends Vue
{
    public get userIdentity(): AuthModel
    {
        return this.$store.state.auth.identity;
    }

    public get subscribedPlayerId(): string
    {
        return this.$store.state.auth.subscribedPlayerId || null;
    }

    public async setExternalUserId(): Promise<void>
    {
        await this.$OneSignal.login(this.userIdentity.publicId);
    }

    public async mounted(): Promise<void>
    {
        if (this.userIdentity && this.$OneSignal)
        {
            const notifications = this.$OneSignal.Notifications;

            if (!notifications.permission)
                await this.$OneSignal.Notifications.requestPermission();

            if (!notifications.permission || !notifications.isPushSupported())
            {
                this.$alert.error(this.$t('[[[Aplikacja nie ma uprawnień do wysyłania powiadomień PUSH]]]'));

                return;
            }

            let attepmts = 0;

            const interval = setInterval(async () =>
            {
                if (attepmts === 4)
                    clearInterval(interval);

                await this.$OneSignal.login(this.userIdentity.publicId);
                attepmts++;
            }, 500);
        }
    }

}
</script>
