<template>
    <multiselect
        ref="multiselect" v-model="model" :options="options" :name="name" :loading="loading" :label="label" :track-by="trackBy" @search-change="onSearchChanged" @open="open()" @close="close()"
        :placeholder="placeholder || $t('[[[wybierz...]]]')" :select-label="''" :selected-label="''" :deselect-label="''" :multiple="multiple" :searchable="true" :options-limit="limit"
        :internal-search="false" :clear-on-select="false" :close-on-select="true" :max-height="300" :show-no-results="true" :hide-selected="false" :disabled="disabled"
    >
        <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
        <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
        <template #singleLabel="{ option }"><slot name="selected" :option="option"></slot></template>
        <template #option="{ option }"><slot name="option" :option="option"></slot></template>
    </multiselect>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch, Debounce, Emit } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';
import Multiselect from '@suadelabs/vue3-multiselect/src/Multiselect.vue';

@Options({
    name: 'ideo-autocomplete',
    components: {
        'multiselect': Multiselect
    }
})
export default class IdeoAutocomplete extends Vue
{
    @Prop({ default: '' }) public name: string;
    @Prop({ default: null }) public modelValue: any;
    @Prop({ default: 10 }) public limit: number;
    @Prop({ default: false }) public disabled: boolean;
    @Prop({ default: false }) public refresh: boolean;
    @Prop({ default: 'value' }) public trackBy: string;
    @Prop({ default: 'text' }) public label: string;
    @Prop() public placeholder: string;
    @Prop() public fetch!: (id: any) => Promise<Option>;
    @Prop() public search!: (query: string, limit: number) => Promise<Option[]>;
    @Prop({ default: false }) public multiple: boolean;
    @Prop({ default: 0 }) public searchAfter: number;
    @Prop({ default: false }) public getCompanyFromProfile: boolean;

    public options: Option[] = [];
    public model: Option = null;
    public loading: boolean = false;

    public async created(): Promise<void>
    {
        const company = this.$store.state?.auth?.identity?.company;

        if (this.getCompanyFromProfile && company)
        {
            this.model = company;

            return;
        }

        await this.fetchModel(this.modelValue);
    }

    public async searchOptions(query: string): Promise<void>
    {
        if (this.searchAfter <= query.length)
            this.options = await this.search(query, this.limit);
        else
            this.options = [];
    }

    public async fetchModel(value: any): Promise<void>
    {
        if (this.model == null || (this.model != null && this.trackBy in this.model && this.model[this.trackBy] != value))
        {
            if (value != null)
            {
                await this.fetch(value).then((result) => { this.model = result; this.options.push(result); });
            }
            else
                this.model = null;
        }
    }

    @Debounce(500)
    public async onSearchChanged(query: string): Promise<void>
    {
        this.loading = true;
        this.searchOptions(query);
        this.loading = false;
    }

    @Watch('modelValue')
    public onValueChanged(value: any): void
    {
        this.fetchModel(value);
    }

    @Watch('model')
    public onModelChanged(model: Option): void
    {
        this.updateModel(model);
        this.triggerChanged(model);

        if (model == null)
        {
            this.options = [];
        }
    }

    @Emit('update:modelValue')
    public updateModel(model: Option): string
    {
        try
        {
            const event = new Event('input', {
                bubbles: true,
                cancelable: true
            });

            const multiselectRef: any = this.$refs.multiselect;
            const targetElement = multiselectRef?.$el.querySelector(`input`);

            targetElement?.dispatchEvent(event);
        }
        catch (ex)
        {
            this.$log.debug(ex);
        }

        return model != null && this.trackBy in model ? model[this.trackBy] : null;
    }

    @Emit('changed')
    public triggerChanged(model: Option): Option
    {
        return model;
    }

    public open(): void
    {
        this.searchOptions('');

        if (this.refresh)
        {
            this.searchOptions('');
        }
    }

    public close(): void
    {
        if (this.refresh)
        {
            if (this.model == null)
            {
                this.options = [];
            }
            else
            {
                this.options = this.options.filter(x => x[this.trackBy] == this.model[this.trackBy]);
            }
        }
    }

    public focus(): void
    {
        const multiselectRef: any = this.$refs.multiselect;
        const targetElement = multiselectRef?.$el.querySelector(`input`);

        targetElement?.focus();
    }
}
</script>

<style lang="scss" scoped>
</style>
