import { isVisibleForSuperUser } from "@/modules/renter/common/helpers/featureFlags";

export default {
    items: [
        {
            name: '[[[Strona główna]]]',
            icon: 'fad fa-fw fa-chart-line',
            namespace: 'Ideo.Core.Renters.Application.Permissions.Renter',
            auth: { all: ['DashboardPermissions@View'], any: [] },
            children: [
                {
                    name: '[[[Dashboard]]]',
                    route: 'renter-dashboard',
                    visible: true,
                },
                {
                    name: '[[[Mapa]]]',
                    route: 'renter-dashboard-map',
                    visible: true
                }
            ]
        },
        {
            name: '[[[Moje zadania]]]',
            icon: 'fad fa-fw fa-list',
            namespace: 'Ideo.Core.Renters.Application.Permissions.Renter',
            auth: { all: ['TasksPermissions@DoTask'], any: [] },
            children: [
                {
                    name: '[[[Zadania]]]',
                    route: 'renter-tasks-management',
                    visible: true,
                    children: [
                        {
                            name: '[[[Zadania do wykonania]]]',
                            route: 'renter-tasks-management',
                            visible: false
                        },
                        {
                            name: '[[[Zadanie do wykonania]]]',
                            route: 'renter-tasks-management-run',
                            visible: false
                        },
                        {
                            name: '[[[Notatka do zadania]]]',
                            route: 'renter-tasks-management-add-note',
                            visible: false
                        },
                    ]
                },
                {
                    name: '[[[Mapa]]]',
                    route: 'renter-tasks-map',
                    visible: true,
                    children: []
                }
            ]
        },
        {
            name: '[[[Moje trasy]]]',
            route: 'renter-drivers-routes-management',
            icon: 'fad fa-fw fa-map',
            namespace: 'Ideo.Core.Renters.Application.Permissions.Renter',
            auth: { all: ['RoutesPermissions@Drive'], any: [] },
            children: [
                {
                    name: '[[[Moja trasa]]]',
                    route: 'renter-drivers-routes-management-route',
                    visible: false
                }
            ]
        },
        {
            name: '[[[Moje konto]]]',
            icon: 'fad fa-fw fa-user',
            visible: false,
            children: [
                {
                    name: '[[[Profil użytkownika]]]',
                    route: 'core-account-profile',
                    icon: 'fa fa-fw fa-user-circle'
                },
                {
                    name: '[[[Zmiana hasła]]]',
                    route: 'core-account-password',
                    icon: 'fa fa-fw fa-toggle-on'
                },
                {
                    name: '[[[Powiadomienia]]]',
                    route: 'core-account-notifications',
                    icon: 'fas fa-fw fa-envelope-open'
                },
                {
                    name: '[[[Miejsce logowania]]]',
                    route: 'core-account-devices',
                    icon: 'fad fa-fw fa-phone-laptop'
                },
                {
                    name: '[[[Indywidualne tokeny]]]',
                    route: 'core-pats',
                    icon: 'fas fa-fw fa-key'
                }
            ]
        },
        {
            name: '[[[Trasy dla Kierowców]]]',
            icon: 'fa-regular fa-truck',
            auth: { all: ['RoutesPermissions@View'], any: [] },
            namespace: 'Ideo.Core.Renters.Application.Permissions.Renter',
            children: [
                {
                    name: '[[[Mapa]]]',
                    route: 'renter-drivers-routes-map'
                },
                {
                    name: '[[[Mapa]]]',
                    route: 'renter-drivers-routes-map-city',
                    visible: false
                },
                {
                    name: '[[[Lista]]]',
                    route: 'renter-drivers-routes-list'
                },
                {
                    name: '[[[Trasy archiwalne]]]',
                    route: 'renter-drivers-routes-archived',
                    auth: { all: ['RoutesPermissions@Edit'], any: [] }
                },
                {
                    name: '[[[Zlecenia]]]',
                    route: 'renter-drivers-routes-commissions',
                    auth: { all: ['RoutesPermissions@Edit'], any: [] }
                }
            ]
        },
        {
            name: '[[[Rezerwacje]]]',
            icon: 'fa fa-fw fa-calendar',
            namespace: 'Ideo.Core.Renters.Application.Permissions.Renter',
            children: [
                {
                    name: '[[[Lista rezerwacji]]]',
                    route: 'renter-reservations',
                    auth: { all: ['ReservationsPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Szczegóły rezerwacji]]]',
                            route: 'renter-reservations-details',
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Kalendarz]]]',
                    route: 'renter-calendar',
                    auth: { all: ['CalendarPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Widok tygodniowy]]]',
                            route: 'renter-calendar',
                            visible: false
                        },
                        {
                            name: '[[[Kalendarz Pracowniczy]]]',
                            route: 'renter-calendar-employee',
                            visible: false
                        }
                    ]
                },
            ]
        },
        {
            name: '[[[Obiekty]]]',
            icon: 'fa fa-fw fa-house',
            namespace: 'Ideo.Core.Renters.Application.Permissions.Renter',
            children: [
                {
                    name: '[[[Lista nieruchomości]]]',
                    route: 'renter-properties',
                    auth: { all: ['PropertiesPermissions@Edit'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie nieruchomości]]]',
                            route: 'renter-properties-add',
                            auth: { all: ['PropertiesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja nieruchomości]]]',
                            route: 'renter-properties-edit',
                            auth: { all: ['PropertiesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Historia nieruchomości]]]',
                    route: 'renter-properties-info',
                    auth: { all: ['PropertiesPermissions@View'], any: [] }
                },
                {
                    name: '[[[Historia nieruchomości]]]',
                    route: 'renter-properties-info-item',
                    auth: { all: ['TasksPermissions@ViewPropertyActivity'], any: [] },
                    visible: false
                },
                {
                    name: '[[[Notatki Koordynatorów]]]',
                    route: 'renter-notes',
                    auth: { all: ['NotesPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie Notatki]]]',
                            route: 'renter-notes-add',
                            auth: { all: ['NotesPermissions@Create'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja Notatki]]]',
                            route: 'renter-notes-edit',
                            auth: { all: ['NotesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Inwentaryzacje]]]',
                    route: 'renter-stocktakes',
                    icon: 'fad fa-fw fa-pen',
                    auth: { all: ['StocktakesPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodawanie inwentaryzacji]]]',
                            route: 'renter-stocktakes-add',
                            auth: { all: ['StocktakesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Planowanie inwentaryzacji]]]',
                            route: 'renter-stocktakes-planned',
                            auth: { all: ['StocktakesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja inwentaryzacji]]]',
                            route: 'renter-stocktakes-edit',
                            auth: { all: ['StocktakesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Dodanie Notatki]]]',
                            route: 'renter-stocktakes-add-note',
                            auth: { all: ['StocktakesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja Notatki]]]',
                            route: 'renter-stocktakes-edit-note',
                            auth: { all: ['StocktakesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Nieruchomości robocze]]]',
                    route: 'renter-custom-properties',
                    auth: { all: ['CustomPropertiesPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie nieruchomości roboczej]]]',
                            route: 'renter-custom-properties-add',
                            auth: { all: ['CustomPropertiesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja nieruchomości roboczej]]]',
                            route: 'renter-custom-properties-edit',
                            auth: { all: ['CustomPropertiesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
            ]
        },
        {
            name: '[[[Właściciele]]]',
            route: 'renter-owners',
            icon: 'fad fa-fw fa-user',
            namespace: 'Ideo.Core.Renters.Application.Permissions.Renter',
            auth: { all: ['OwnersPermissions@View'], any: [] },
            children: [
                {
                    name: '[[[Dodanie właściciela]]]',
                    route: 'renter-owners-add',
                    auth: { all: ['OwnersPermissions@Edit'], any: [] },
                    visible: false
                },
                {
                    name: '[[[Edycja właściciela]]]',
                    route: 'renter-owners-edit',
                    auth: { all: ['OwnersPermissions@Edit'], any: [] },
                    visible: false
                }
            ]
        },
        {
            name: '[[[Zadania]]]',
            icon: 'fa fa-fw fa-check-circle',
            namespace: 'Ideo.Core.Renters.Application.Permissions.Renter',
            auth: { all: ['TasksPermissions@View'], any: [] },
            children: [
                {
                    name: '[[[Sprzątanie]]]',
                    route: 'renter-tasks-cleaning',
                    auth: { all: ['TasksPermissions@ViewCleaning'], any: [] },
                    children: []
                },
                {
                    name: '[[[Inspekcje]]]',
                    route: 'renter-tasks-inspection',
                    auth: { all: ['TasksPermissions@ViewInspection'], any: [] },
                    children: []
                },
                {
                    name: '[[[Konserwacje]]]',
                    route: 'renter-tasks-maintenance',
                    auth: { all: ['TasksPermissions@ViewMaintenance'], any: [] },
                    children: []
                },
                {
                    name: '[[[Wszystkie zadania]]]',
                    route: 'renter-tasks',
                    auth: { all: [], any: ['TasksPermissions@Edit'] },
                    children: [
                        {
                            name: '[[[Przeterminowane]]]',
                            route: 'renter-tasks-expired',
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Dodanie zadania]]]',
                    route: 'renter-tasks-add',
                    auth: { all: [], any: ['TasksPermissions@EditInspection', 'TasksPermissions@EditMaintenance', 'TasksPermissions@Edit'] },
                    visible: false
                },
                {
                    name: '[[[Edycja zadania]]]',
                    route: 'renter-tasks-edit',
                    auth: { all: [], any: ['TasksPermissions@EditInspection', 'TasksPermissions@EditMaintenance', 'TasksPermissions@Edit'] },
                    visible: false
                },
                {
                    name: '[[[Usunięte zadania]]]',
                    route: 'renter-tasks-deleted',
                    auth: { all: ['TasksPermissions@Edit'], any: [] },
                    children: [{
                        name: '[[[Usunięte]]]',
                        route: 'renter-tasks-deleted-form',
                        visible: false
                    }]
                },
            ]
        },
        {
            name: '[[[Ustawienia]]]',
            icon: 'fad fa-fw fa-cog',
            namespace: 'Ideo.Core.Renters.Application.Permissions.Renter',
            children: [
                {
                    name: '[[[Ustawienia globalne]]]',
                    route: 'renter-global-settings',
                    namespace: 'Ideo.Core.Common.Application.Permissions.Core',
                    auth: { all: ['AdminPermissions@Security'], any: [] },
                    children: []
                },
                {
                    name: '[[[Zaopatrzenie]]]',
                    route: 'renter-supplies',
                    auth: { all: ['SuppliesPermissions@View'], any: [] },
                    children: []
                },
                {
                    name: '[[[Dane wydziałów]]]',
                    route: 'renter-departments',
                    namespace: 'Ideo.Core',
                    auth: { all: ['StandardPermissions@Superuser'], any: [] },
                    children: []
                },
                {
                    name: '[[[Regiony]]]',
                    route: 'renter-regions',
                    auth: { all: ['RegionsPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie regionu]]]',
                            route: 'renter-regions-add',
                            auth: { all: ['RegionsPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja regionu]]]',
                            route: 'renter-regions-edit',
                            auth: { all: ['RegionsPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Miasta]]]',
                    route: 'renter-cities',
                    auth: { all: ['CitiesPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie miasta]]]',
                            route: 'renter-cities-add',
                            auth: { all: ['CitiesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja miasta]]]',
                            route: 'renter-cities-edit',
                            auth: { all: ['CitiesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Dzielnice]]]',
                    route: 'renter-districts',
                    auth: { all: ['DistrictsPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie dzielnicy]]]',
                            route: 'renter-districts-add',
                            auth: { all: ['DistrictsPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja dzielnicy]]]',
                            route: 'renter-districts-edit',
                            auth: { all: ['DistrictsPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Firmy]]]',
                    route: 'renter-companies',
                    auth: { all: ['CompaniesPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie firmy]]]',
                            route: 'renter-companies-add',
                            auth: { all: ['CompaniesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja firmy]]]',
                            route: 'renter-companies-edit',
                            auth: { all: ['CompaniesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Checklisty]]]',
                    route: 'renter-checklists',
                    auth: { all: ['ChecklistsPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodawanie checklisty]]]',
                            route: 'renter-checklists-add',
                            auth: { all: ['ChecklistsPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja checklisty]]]',
                            route: 'renter-checklists-edit',
                            auth: { all: ['ChecklistsPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Tagi nieruchomości]]]',
                    route: 'renter-tags',
                    auth: { all: ['TagsPermissions@View'], any: [] },
                },
                {
                    name: '[[[Tagi zadań]]]',
                    route: 'renter-tasks-tags',
                    // permissions
                    auth: { all: [], any: ['TagsPermissions@View', 'TaskTagsPermissions@View'] },
                }
            ]
        },
        {
            name: '[[[Raporty]]]',
            icon: 'fad fa-fw fa-gauge',
            namespace: 'Ideo.Core.Renters.Application.Permissions.Renter',
            auth: { all: [], any: ['CostsPermissions@View', 'CostsPermissions@ViewMonthlyReport', 'SalariesPermissions@ViewCoordinators', 'SalariesPermissions@ViewDrivers', 'SalariesPermissions@ViewMaintenance', 'SalariesPermissions@ViewCleaning', 'AbsencesPermissions@View', 'StatisticsPermissions@ViewTasksAndCosts'] },
            children: [
                {
                    name: '[[[Raporty z rozliczeń]]]',
                    route: 'renter-cost-reports',
                    icon: 'fa fa-fw fa-user',
                    auth: { all: ['CostsPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodaj koszt]]]',
                            route: 'renter-cost-reports-add',
                            auth: { all: ['CostsPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Opracowanie kosztu]]]',
                            route: 'renter-cost-reports-management',
                            auth: { all: [], any: ['CostsPermissions@ManageRenters', 'CostsPermissions@ManageRentersStorage', 'CostsPermissions@ManageOwner', 'CostsPermissions@ManageGuest'] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Back Office]]]',
                    route: 'renter-cost-monthly-report',
                    auth: { all: ['CostsPermissions@ViewMonthlyReport'], any: [] },
                },
                {
                    name: '[[[Rozliczenia pracowników]]]',
                    route: 'renter-salaries-timesheet',
                    auth: { all: [], any: ['SalariesPermissions@ViewCoordinators', 'SalariesPermissions@ViewDrivers', 'SalariesPermissions@ViewMaintenance', 'SalariesPermissions@ViewCleaning', 'AbsencesPermissions@View'] },
                    children: [
                        {
                            name: '[[[Grafik pracowniczy]]]',
                            route: 'renter-salaries-timesheet',
                            auth: { all: [], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Urlopy]]]',
                            route: 'renter-salaries-absences',
                            auth: { all: ['AbsencesPermissions@View'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Wynagrodzenia HK]]]',
                            route: 'renter-salaries-hk',
                            auth: { all: ['SalariesPermissions@ViewCleaning'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Wynagrodzenia Konserwatorzy]]]',
                            route: 'renter-salaries-maintenance',
                            auth: { all: ['SalariesPermissions@ViewMaintenance'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Wynagrodzenia Koordynatorzy]]]',
                            route: 'renter-salaries-coordinator',
                            auth: { all: ['SalariesPermissions@ViewCoordinators'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Wynagrodzenia Kierowcy]]]',
                            route: 'renter-salaries-driver',
                            auth: { all: ['SalariesPermissions@ViewDrivers'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Wynagrodzenia Pracownicy]]]',
                            route: 'renter-salaries-employees',
                            auth: { all: [], any: [] },
                            visible: false,
                            children: [
                                {
                                    name: '[[[Szczegóły]]]',
                                    route: 'renter-salaries-employees-details',
                                    auth: { all: [], any: [] },
                                    visible: false
                                }
                            ]
                        },
                        {
                            name: '[[[Statystyki]]]',
                            route: 'renter-salaries-statistics',
                            auth: { all: [], any: [] },
                            visible: false
                        },
                    ]
                },
                {
                    name: '[[[Statystyki]]]',
                    auth: { all: ['StatisticsPermissions@ViewTasksAndCosts'], any: [] },
                    route: 'renter-costs-statistics-employees',
                    children: [
                        {
                            name: '[[[Statystyki wyniosków]]]',
                            route: 'renter-costs-statistics-requests',
                            auth: { all: [], any: [] },
                            visible: false,
                        },
                        {
                            name: '[[[Statystyki kosztów w obiektach]]]',
                            route: 'renter-costs-statistics-properties',
                            auth: { all: [], any: [] },
                            visible: false,
                        }
                    ]
                }
            ]
        },
        {
            name: '[[[Administracja]]]',
            icon: 'fad fa-fw fa-desktop',
            namespace: 'Ideo.Core.Common.Application.Permissions.Core',
            children: [
                {
                    name: '[[[Użytkownicy]]]',
                    route: 'core-users',
                    icon: 'fa fa-fw fa-user',
                    auth: { all: ['UsersPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie użytkownika]]]',
                            route: 'core-users-add',
                            auth: { all: ['UsersPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja użytkownika]]]',
                            route: 'core-users-edit',
                            auth: { all: ['UsersPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Organizacja]]]',
                    route: 'core-units',
                    icon: 'fas fa-fw fa-sitemap',
                    auth: { all: ['OrganizationPermissions@View'], any: [] },
                    visible: false,
                    children: [
                        {
                            name: '[[[Dodanie działu]]]',
                            route: 'core-units-add',
                            auth: { all: ['OrganizationPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja działu]]]',
                            route: 'core-units-edit',
                            auth: { all: ['OrganizationPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Zespoły]]]',
                    route: 'core-teams',
                    icon: 'fas fa-fw fa-user-friends',
                    auth: { all: ['TeamsPermissions@View'], any: [] },
                    visible: false,
                    children: [
                        {
                            name: '[[[Dodanie zespołu]]]',
                            route: 'core-teams-add',
                            auth: { all: ['TeamsPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja zespołu]]]',
                            route: 'core-teams-edit',
                            auth: { all: ['TeamsPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Zastępstwa]]]',
                    route: 'core-substitutions',
                    icon: 'fa fa-fw fa-user',
                    auth: { all: ['SubstitutionsPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie zastępstwa]]]',
                            route: 'core-substitutions-add',
                            auth: { all: ['SubstitutionsPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja zastępstwa]]]',
                            route: 'core-substitutions-edit',
                            auth: { all: ['SubstitutionsPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Ustawienia]]]',
                    icon: 'fad fa-fw fa-cogs',
                    children: [
                        {
                            name: '[[[Bezpieczeństwo]]]',
                            route: 'core-security',
                            icon: 'fas fa-fw fa-shield-alt',
                            auth: { all: ['AdminPermissions@Security'], any: [] }
                        },
                        {
                            name: '[[[Poczta]]]',
                            route: 'core-mailing',
                            icon: 'fas fa-fw fa-shield-alt',
                            auth: { all: ['AdminPermissions@Mailing'], any: [] }
                        }
                    ]
                },
                {
                    name: '[[[Powiadomienia]]]',
                    route: 'core-notifications-templates',
                    icon: 'far fa-fw fa-ballot-check',
                    auth: { all: ['NotificationsPermissions@Access'], any: [] },
                    children: [
                        {
                            name: '[[[Dodaj szablon]]]',
                            route: 'core-notifications-templates-add',
                            auth: { all: ['NotificationsPermissions@Access'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edytuj szablon]]]',
                            route: 'core-notifications-templates-edit',
                            auth: { all: ['NotificationsPermissions@Access'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Monitoring]]]',
                    icon: 'fad fa-fw fa-analytics',
                    children: [
                        {
                            name: '[[[Historia logowania]]]',
                            route: 'core-authlog',
                            icon: 'fas fa-fw fa-file-import',
                            auth: { all: ['AdminPermissions@Authlog'], any: [] }
                        },
                        {
                            name: '[[[Rejestr zmian]]]',
                            route: 'core-changelog',
                            icon: 'fas fa-fw fa-file-signature',
                            auth: { all: ['AdminPermissions@Changelog'], any: [] },
                            children: [
                                {
                                    name: '[[[Szczegóły zdarzenia]]]',
                                    route: 'core-changelog-diff',
                                    visible: false
                                }
                            ]
                        },
                        {
                            name: '[[[Harmonogram zadań]]]',
                            route: 'core-scheduler',
                            icon: 'fas fa-fw fa-clock',
                            auth: { all: ['SchedulerPermissions@View'], any: [] },
                            children: [
                                {
                                    name: '[[[Historia zadania]]]',
                                    route: 'core-scheduler-history',
                                    visible: false
                                },
                                {
                                    name: '[[[Szczegóły zadania]]]',
                                    route: 'core-scheduler-details',
                                    visible: false
                                }
                            ]
                        },
                        {
                            name: '[[[Monitor kolejek]]]',
                            route: 'core-queues',
                            icon: 'fas fa-fw fa-clock',
                            auth: { all: ['MessagePermissions@View'], any: [] },
                            children: [
                                {
                                    name: "[[[Szczegóły zadania]]]",
                                    route: 'core-queues-details',
                                    visible: false
                                }
                            ]
                        }
                    ]
                },
                {
                    name: '[[[Ustawienia chatów]]]',
                    route: 'chat-room-list',
                    icon: 'fas fa-fw fa-comments',
                    namespace: 'Ideo.Core.Renters.Application.Permissions.Chat',
                    auth: { all: ['ChatPermissions@Manage'], any: [] },
                    children: [
                        {
                            name: '[[[Ustawienia]]]',
                            route: 'chat-room-settings',
                            auth: { all: ['ChatPermissions@Manage'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Role i uprawnienia]]]',
                    route: 'core-roles',
                    icon: 'fa fa-fw fa-users',
                    auth: { all: ['RolesPermissions@View'], any: [] },
                    visible: isVisibleForSuperUser(),
                    children: [
                        {
                            name: '[[[Dodanie roli]]]',
                            route: 'core-roles-add',
                            auth: { all: ['RolesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja roli]]]',
                            route: 'core-roles-edit',
                            auth: { all: ['RolesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Synchronizacja rezerwacji]]]',
                    route: 'renter-reservations-sync-job',
                    auth: { all: ['MessagePermissions@View'], any: [] },
                    visible: isVisibleForSuperUser()
                }
            ]
        },
        {
            name: '[[[Usługi]]]',
            icon: 'fad fa-fw fa-server',
            namespace: 'Ideo.Core.Common.Application.Permissions.Saas',
            visible: false,
            children: [
                {
                    name: '[[[Klienci]]]',
                    route: 'saas-tenants',
                    icon: 'fad fa-fw fa-users-class',
                    auth: { all: ['TenantsPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie klienta]]]',
                            route: 'saas-tenants-add',
                            auth: { all: ['TenantsPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja klienta]]]',
                            route: 'saas-tenants-edit',
                            auth: { all: ['TenantsPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Bazy danych]]]',
                    route: 'saas-databases',
                    icon: 'fad fa-fw fa-database',
                    auth: { all: ['DatabasesPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie serwera]]]',
                            route: 'saas-databases-add',
                            auth: { all: ['DatabasesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja serwera]]]',
                            route: 'saas-databases-edit',
                            auth: { all: ['DatabasesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Serwery plików]]]',
                    route: 'saas-storages',
                    icon: 'fad fa-fw fa-hdd',
                    auth: { all: ['StoragesPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie serwera]]]',
                            route: 'saas-storages-add',
                            auth: { all: ['StoragesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja serwera]]]',
                            route: 'saas-storages-edit',
                            auth: { all: ['StoragesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                }
            ]
        },
        {
            name: '[[[Narzędzia]]]',
            icon: 'fad fa-fw fa-cogs',
            namespace: 'Ideo.Core.Common.Application.Permissions.Core',
            visible: false,
            children: [
                {
                    name: '[[[Źródła danych]]]',
                    route: 'core-datasources',
                    icon: 'fas fa-fw fa-database',
                    auth: { all: ['DataSourcesPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie źródła danych]]]',
                            route: 'core-datasources-add',
                            auth: { all: ['DataSourcesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja źródła danych]]]',
                            route: 'core-datasources-edit',
                            auth: { all: ['DataSourcesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Formularze]]]',
                    route: 'core-forms',
                    icon: 'far fa-fw fa-ballot-check',
                    auth: { all: ['DynamicFormsPermissions@Access'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie formularza]]]',
                            route: 'core-forms-add',
                            auth: { all: ['DynamicFormsPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja formularza]]]',
                            route: 'core-forms-edit',
                            // auth: { all: ['DynamicFormsPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                }
            ]
        },
        {
            name: '[[[Magazyn]]]',
            icon: 'fa-solid fa-boxes-stacked',
            namespace: 'Ideo.Core.Renters.Application.Permissions.Renter',
            auth: { all: ['WarehousePermissions@View'], any: [] },
            children: [
                {
                    name: '[[[Zlecenia magazynowe]]]',
                    route: 'renter-warehouse-orders',
                    icon: 'fa-solid fa-boxes-packing',
                    auth: { all: ['WarehousePermissions@ViewLabels'], any: [] },
                },
            ]
        },
        {
            name: '[[[Repozytorium plików]]]',
            route: 'filemanager',
            icon: 'fad fa-fw fa-folders',
            namespace: 'Ideo.Core.Common.Application.Permissions.Storage',
            auth: { all: ['StoragePermissions@View'], any: [] },
            visible: false
        },
        {
            name: '[[[Kosz]]]',
            route: 'trash',
            icon: 'fad fa-fw fa-trash',
            namespace: 'Ideo.Core.Common.Application.Permissions.Core',
            auth: { all: ['TrashPermissions@View'], any: [] },
            children: [
                {
                    name: '[[[Grupa]]]',
                    route: 'trash-group',
                    visible: false
                }
            ]
        },
        {
            name: '[[[Czaty]]]',
            route: 'chat-myrooms',
            icon: 'fas fa-fw fa-comments',
            namespace: 'Ideo.Core.Renters.Application.Permissions.Chat',
            auth: { all: [], any: [] },
            children: [
                {
                    route: 'chat-myrooms-chat',
                    visible: false
                },
                {
                    name: '[[[Stwórz czat]]]',
                    route: 'chat-add',
                    visible: false
                },
                {
                    name: '[[[Stwórz czat z zadania]]]',
                    route: 'chat-add-from-task',
                    visible: false
                },
                {
                    name: '[[[Stwórz czat z rezerwacji]]]',
                    route: 'chat-add-from-reservation',
                    visible: false
                }
            ]
        }
    ]
};
