import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import type { FilterModel, RoutesListFilterModel, CommissionsFilterModel } from "../types";
import { TaskTypeEnum } from "../services/DriversRoutesService";

export default {
    namespaced: true,
    state: {
        pager: new Pager(1, 10, 'RouteName', 'ASC'),
        filters: Form.createPersistedState<FilterModel>({
            driverPublicId: null,
            cityPublicId: null,
            districtPublicId: null,
            dateFromUtc: null,
            dateToUtc: null,
            taskType: TaskTypeEnum.Cleaning,
            propertyType: null
        }, undefined, 'renterDriversRoutesFilter'),
        routeListPager: new Pager(1, 100, 'RouteName', 'ASC'),
        routeFilters: Form.createPersistedState<RoutesListFilterModel>({
            dateFromUtc: null,
            dateToUtc: null,
            isCompleted: null,
        }, undefined, 'rentersDriversRoutesRouteFilters'),
        commissionPager: new Pager(1, 10, 'PublicId', 'ASC'),
        commissionsFilter: Form.createPersistedState<CommissionsFilterModel>({
            dateCompletedUtc: null
        }, undefined, 'rentersDriversRoutesCommissionsFilters')
    }
};
