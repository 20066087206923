import { Option, Pagination, Resource } from "@/helpers/Interfaces";
import Pager from "@/helpers/Pager";
import axios from "axios";
import { merge } from "lodash";
import { DateTime } from "luxon";
import { ChatRoomsListItemModel } from "@/modules/renter/chat/services/ChatService";
import { $t } from "@/plugins/localization";
import { TaskLinkModel } from "@/modules/renter/tasks/services/TasksService";

/**
 * ReservationsService
 */
export default class ReservationsService
{
    /**
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/renters/reservations', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(publicId: string): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/renters/reservations/${publicId}`)).data;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getReservationsAutocompleteList(search: string, limit: number = 1, propertyPublicId: string = null): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>('admin/renters/reservations/autocomplete', {
            params: {
                search: search,
                limit: limit,
                propertyPublicId
            }
        })).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<CityItemModel>
     */
    public static async fetchReservationAutocompleteModel(publicId: string): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/renters/reservations/autocomplete/${publicId}`)).data;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getReservationsWebSourceAutocompleteList(search: string, limit: number = 10): Promise<string[]>
    {
        return (await axios.get<string[]>('admin/renters/webSource/autocomplete', {
            params: {
                search: search,
                limit: limit
            }
        })).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<CityItemModel>
     */
    public static async fetchReservationWebSourceAutocompleteModel(search: string): Promise<string>
    {
        return (await axios.get<string>(`admin/renters/webSource/autocomplete/single`, {
            params: { search }
        })).data;
    }

    /**
     *
     * @returns Promise<string>
     */
    public static async runReservationsSyncJob(form: GuestyReservationSyncFormModel): Promise<string>
    {
        return (await axios.post<string>(`admin/renters/reservations/synchronization`, form)).data;
    }
}

export interface ListItemModel
{
    id: number;
    publicId: string;
    propertyPublicId: string;
    propertyName: string;
    clientName: string;
    clientSurname: string;
    reservationType: string;
    clientTelephone: string;
    clientEmail: string;
    numberOfGuests?: number;
    numberOfNights: number;
    avantioBookingCode: number;
    avantioLocalizator: string;
    isPet: boolean;
    startDate: DateTime;
    endDate: DateTime;
    arrivalDate: DateTime;
    departureDate: DateTime;
    bookingType: string;
    city: string;
    webSource: string;
}

export interface FilterModel
{
    today: boolean;
    tomorrow: boolean;
    arrival: boolean;
    departure: boolean;
    custom?: DateTime;
    localizator: string;
    status: Array<string> | string;
    citiesIds: string[] | string;
    districtsIds: string[] | string;
    propertiesIds: any[] | string;
    webSource: string;
}

export interface FormModel
{
    id: number;
    publicId: string;
    propertyPublicId: string;
    guestyId: string
    bookingCode: number;
    localizator: string;
    bookingType: string;
    accommodationCode?: number;
    startDate?: DateTime;
    endDate?: DateTime;
    arrivalDate?: DateTime;
    departureDate?: DateTime;
    clientName: string;
    clientSurname: string;
    clientTelephone: string;
    clientEmail: string;
    numberOfGuests?: number;
    numberOfNights: number;
    adultsNumber?: number;
    child1Age?: number;
    child2Age?: number;
    child3Age?: number;
    child4Age?: number;
    child5Age?: number;
    child6Age?: number;
    isPet: boolean;
    reservationChats: ChatRoomsListItemModel[];
    reservationTasks: TaskLinkModel[];
    webSource: string;
    price: number;
}

export interface ReservationsSyncJobFormModel {
    dateReservationFromUtc: DateTime;
    dateReservationToUtc: DateTime;
    dateModificationFromUtc: DateTime;
    dateModificationToUtc: DateTime;
    returnCancelled: boolean;
}

export interface GuestyReservationSyncFormModel {
    checkoutDateFromUtc: DateTime;
    checkoutDateToUtc: DateTime;
}

enum StatusEnum {
    BAJOPETICION = 'BAJOPETICION',
    CANCELLED = 'CANCELLED',
    CANCELLED_PROPIETARIO = 'CANCELLED_PROPIETARIO',
    CONFIRMADA = 'CONFIRMADA',
    FACTURADA = 'FACTURADA',
    GARANTIA = 'GARANTIA',
    PAID = 'PAID',
    PETICION_DISPONIBILIDAD = 'PETICION_DISPONIBILIDAD',
    PETICION_INFORMACION = 'PETICION_INFORMACION',
    PETICION_TPV = 'PETICION_TPV',
    PROPIETARIO = 'PROPIETARIO',
    UNAVAILABLE = 'UNAVAILABLE',
    UNPAID = 'UNPAID',
    CONFLICTED = 'CONFLICTED'
}

const StatusOptions = (): Option[] => ([
    { value: StatusEnum.BAJOPETICION, text: $t('[[[Under request]]]') },
    { value: StatusEnum.CANCELLED, text: $t('[[[Cancelled]]]') },
    { value: StatusEnum.CANCELLED_PROPIETARIO, text: $t('[[[Cancelled by owner]]]') },
    { value: StatusEnum.CONFIRMADA, text: $t('[[[Confirmed]]]') },
    { value: StatusEnum.FACTURADA, text: $t('[[[Facturada]]]') },
    { value: StatusEnum.GARANTIA, text: $t('[[[Exclusivity]]]') },
    { value: StatusEnum.PAID, text: $t('[[[Paid]]]') },
    { value: StatusEnum.PETICION_DISPONIBILIDAD, text: $t('[[[Peticion disponibilidad]]]') },
    { value: StatusEnum.PETICION_INFORMACION, text: $t('[[[Peticion informacion]]]') },
    { value: StatusEnum.PETICION_TPV, text: $t('[[[Attempt of reservation by Credit Card]]]') },
    { value: StatusEnum.PROPIETARIO, text: $t('[[[Owner Booking]]]') },
    { value: StatusEnum.UNAVAILABLE, text: $t('[[[Not Available]]]') },
    { value: StatusEnum.UNPAID, text: $t('[[[Pre-booking]]]') },
    { value: StatusEnum.CONFLICTED, text: $t('[[[Booking with conflict]]]') }
]);

const StatusName = (value: StatusEnum): string => StatusOptions().find(s => s.value === value)?.text || StatusEnum[value];

export {
    StatusEnum,
    StatusOptions,
    StatusName
};

export interface ReservationItemModel extends FormModel {}
