import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import { FilterModel } from '../services/StocktakesService';

export default {
    namespaced: true,
    state: {
        filter: Form.createPersistedState<FilterModel>({
            propertyPublicId: null,
            regionPublicId: null,
            inspectorPublicId: null,
            dateFromUtc: null,
            dateDueUtc: null,
            citiesIds: [],
            districtsIds: []
        }, undefined, 'renterStocktakesFilters'),
        pager: new Pager(1, 100, 'dateCreatedUtc', 'DESC'),
        suggestionsPager: new Pager(1, 100, 'propertyName', 'ASC'),
        propertyPublicId: null
    }
};
