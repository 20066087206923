import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import { DateTime } from "luxon";
import { EmployeesFilterModel, PropertiesFilterModel, RequestsFilterModel } from "../services/CostStatisticsService";

const firstDayOfThisMonth = DateTime.utc().startOf('month');

export default {
    namespaced: true,
    state: {
        employees: {
            pager: new Pager(1, 100, 'GivenName', 'ASC'),
            filter: Form.createPersistedState<EmployeesFilterModel>({
                searchDate: firstDayOfThisMonth,
                userPublicId: null,
                cityPublicId: null,
                regionPublicId: null
            }, undefined, 'renterCostStatisticsEmployeesFilter')
        },
        requests: {
            filter: Form.createPersistedState<RequestsFilterModel>({
                searchDate: firstDayOfThisMonth,
                cityPublicId: null,
                regionPublicId: null
            }, undefined, 'renterCostStatisticsRequestsFilter')
        },
        properties: {
            filter: Form.createPersistedState<PropertiesFilterModel>({
                searchDate: firstDayOfThisMonth,
                cityPublicId: null,
                regionPublicId: null
            }, undefined, 'renterCostStatisticsPropertiesFilter')
        }
    }
};
