import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import { FilterModel } from "../services/ChatService";

export default {
    namespaced: true,
    state: {
        filter: Form.createPersistedState<FilterModel>({
            name: '',
            propertyName: '',
            status: ['Active']
        }, undefined, 'renterChatFilter'),
        pager: new Pager(1, 20),
        users: {
            filter: Form.createPersistedState({
                name: ''
            }, undefined, 'renterChatUsersFilter'),
            pager: new Pager(1, 100, 'Id', 'ASC')
        },
        messages: {
            filter: Form.createPersistedState({
                authorName: '',
                dateFromUtc: null,
                dateToUtc: null,
                message: ''
            }, undefined, 'renterChatMessagesFilter'),
            pager: new Pager(1, 100, 'DateCreatedUtc', 'DESC')
        },
        removeNotification: null as string
    }
};
